import React from "react"
import styles from './index.module.css'

const Hardware = () =>{
    return(
        <div className={styles.container}>
            
            <div className={styles.title}>推荐硬件</div>
            <div className={styles.content}>
                <div className={styles.img}>
                    <img
                        src={
                            "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/25/3440c97a8afb441fb1f88dbe40e59003"
                          }
                          alt=""
                    />
                </div>
                <div className={styles.word}>
                    <div className={styles.wordTitle}>智慧闸机</div>
                    <div className={styles.wordContent}>
                       
                        <div className={styles.wordSubTitle}>
                            品质硬件，经久耐用
                        </div>                            
                    </div>
                    <div className={styles.word1}>防水防尘防潮，高亮度LED发光时长100000小时，亮度可根据环境自适应调节</div>
                    <div className={styles.wordContent}>
                        <div className={styles.wordSubTitle}>
                            专利技术，安全可靠
                        </div>                            
                    </div>
                    <div className={styles.word1}>落杆6级减速、水平落杆免支撑，防砸遇阻无须外接设备，拥有该产品12项发明及相关专利技术</div>
                    <div className={styles.wordContent}>
                        <div className={styles.wordSubTitle}>
                        广告投放，高效运营
                        </div>                            
                    </div>
                    <div className={styles.word1}>广告叶片拔插易更换，广告内容、时段按需投放，灵活便捷，可面向多运营商</div>
                    <div className={styles.wordContent}>
                        <div className={styles.wordSubTitle}>
                        实时通讯，数据上云
                        </div>                            
                    </div>
                    <div className={styles.word1}>开放式对接协议，支持异地同网管理，便于物业、地产集团化，可视化视图数据展示</div>
                </div>
            </div>
        </div>
    )
}

export default Hardware;