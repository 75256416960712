import React from 'react';
import styles from './index.module.css'

const Staff = () => {
  return(
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>物业员工APP</div>
        <div className={styles.subTitle}>简化工作流程、提升员工调度灵活性，高效管理团队</div>
        <div className={styles.group}>
          <div className={styles.phone1}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/25/1cd830a514a643fa9a40a1de96124532"
              }
              alt=""
            />
          </div>
          <div className={styles.phone1}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/25/01916cccb75c4cfeb498f3626266fbee"
              }
              alt=""
            />
          </div>
          <div className={styles.phone1}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/25/804b6dc86bde4643943651012fb1aacb"
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    )
}

export default Staff;