import React from "react"

import Layout from "../components/layout/index"
import Banner from "../components/smart-property/banner/index"
import Community from "../components/smart-property/community/index"
import Cloud from "../components/smart-property/cloud/index"
import Staff from "../components/smart-property/staff/index"
import Hardware from "../components/smart-property/hardware/index"
  


// import Image from "../components/image"
import SEO from "../components/seo"

// const Firends = ()=>{
//   return <></>
// }

const SmartProperty = () => (
  <Layout page="SmartProperty">
    <SEO title="联每户" />
    <Banner />
    <Community />
    <Cloud />
    <Staff />
    <Hardware />
  </Layout>
)

export default SmartProperty
