import React from 'react';
import styles from './index.module.css'

const Banner = ({ bannerUrl }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          智慧物业
          <br />
          多维工具，打造数字化社区管理
        </div>
        <div className={styles.img1}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/882f25a02a4d4f399c9fdf728c741422"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.img2}>
          <img
            src={
              bannerUrl
                ? bannerUrl
                : "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/22/84acba32871845a9a58a6a3fde4df1f0"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.img3}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/22/bdfdc7478bec4f29a802ef97a694cb32"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.img4}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/bcc3687c28614e189644910e307cb6df"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.imgLogo}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/22/4f6932d4022e48fba5d461020d19e3e8"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
  )
}

export default Banner
