import React from 'react';
import styles from './index.module.css'

const Cloud = () => {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>云端大数据运营管理界面</div>
          <div className={styles.subTitle}>
          运营社区服务、运维关键数据可视，社区设备、人员核心数据沉淀，组织运营效率提升
          </div>
          <div className={styles.imgBox}>
            <div className={styles.img1}>
            <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/25/a0f2e91ded804013b8dd6d603a5f61cf"
                }
                alt=""
                style={{ width: "100%", height: "100%"}}
              />            
            </div>
            <div className={styles.img2}>
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/25/48a64d6606f94b2885fecdd1049b4e69"
                }
                alt=""
                style={{ width: "100%", height: "100%"}}
              />            
            </div>
            <div className={styles.img3}>
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/25/af0f1dc6a4db406d9716d9e2b91577ca"
                }
                alt=""
                style={{ width: "100%", height: "100%"}}
              />            
            </div>
          </div>
          
        </div>
      </div>
    )
  }
  
  export default Cloud;